try {
    window.$ = window.jQuery = require('jquery');
    require('angular');
} catch (e) {}

import angular from 'angular';
import angularCookies from 'angular-cookies';

require('./components/controllers');

require('./modules/draggable');

var app = angular.module('pandemic', [
    'pandemic.controllers',
    'draggable'
]);