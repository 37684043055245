angular.module('draggable', [])
.directive('draggable', ['$document', function($document) {
    return {
        link: function(scope, element, attr) {
            var startX = 14, startY = 14, x = scope.city.x, y = scope.city.y;

            var key = scope.$eval(attr.key);


            element.css({
                top: scope.city.y + 'px',
                left: scope.city.x + 'px'
            });

            element.css({
                cursor: 'pointer'
            });

            element.on('mousedown', function(event) {
                if (!scope.editMode) {
                    return;
                }

                // Prevent default dragging of selected content
                event.preventDefault();
                startX = event.pageX - x;
                startY = event.pageY - y;
                $document.on('mousemove', mousemove);
                $document.on('mouseup', mouseup);
            });

            function mousemove(event) {
                y = event.pageY - startY;
                x = event.pageX - startX;
                element.css({
                    top: y + 'px',
                    left:  x + 'px'
                });

                scope.city.x = x;
                scope.city.y = y;
            }

            function mouseup() {
                $document.off('mousemove', mousemove);
                $document.off('mouseup', mouseup);

                // Delete 
                if (scope.city.x > 800) {
                    console.log(scope.cities);
                    scope.cities.splice(key, 1);
                    element.remove();
                }

                scope.save();
            }
        }
    };
}]);